<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6 w-1/2">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Shipment Doc</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.shipment_number"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Customer Data</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.customer_code"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>SO Data</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.sales_order_code"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>DO Data</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.delivery_order_code"
                :disabled="true"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>POD Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full"
                v-model="table.data.proof_of_delivery_date"
                type="date"
                :max="dateNow"
                :min="table.data.delivery_date"
                v-validate="'required'"
              />
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>POD Status</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedStatus"
                :options="optionalStatus"
                :disabled="editOrShow"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Select one"
                :searchable="true"
                :custom-label="customLabelStatus"
                @select="handleSelectStatus()"
              />
            </div>
          </div>
        </div>
        <div
          class="vx-row mb-6"
          v-if="
            selectedStatus.ID == 4 ||
            selectedStatus.ID == 5 ||
            selectedStatus.ID == 6
          "
        >
          <div class="vx-col sm:w-1/5 w-full">
            <span>Reason</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <multiselect
                class="selectExample"
                v-model="selectedReason"
                :options="optionReason"
                :disabled="editOrShow"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="100"
                :limit="3"
                placeholder="Select one"
                :searchable="true"
                track-by="id"
                label="reason"
                name="reason"
                @select="handleSelectReason()"
              />
              <span class="text-danger text-sm" v-show="errors.has('reason')"
                >Reason is required</span
              >
            </div>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Document</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col w-1/2">
              <vs-checkbox
                v-model="table.data.is_document"
                color="success"
                :disabled="editOrShow"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div class="vx-row flex justify-between mt-5 m-10">
        <div class="flex flex-row justify-between gap-2">
          <vs-button
            class="mb-2"
            v-on:click="SubmitForm(false)"
            v-if="!editOrShow"
          >
            Save
          </vs-button>
          <vs-button
            class="mb-2"
            v-on:click="SubmitForm(true)"
            v-if="!editOrShow"
          >
            Process
          </vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
      </div>
      <div v-if="itemLine.length > 0" class="separator">
        <span>REGULER</span>
      </div>
      <vs-table
        v-if="itemLine.length > 0"
        stripe
        border
        description
        :sst="true"
        :data="itemLine"
        :max-items="itemLine.length"
      >
        <template slot="thead">
          <vs-th style="width: 28%">SKU Name</vs-th>
          <vs-th style="width: 12%">SKU Code</vs-th>
          <vs-th style="width: 5%">Quantity</vs-th>
          <vs-th style="width: 10%">Accepted Quantity</vs-th>
          <vs-th style="width: 30%">Rejected Quantity</vs-th>
          <vs-th style="width: 15%">Note</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="'ae' + indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.item_name">
              {{ tr.item_name }}
            </vs-td>
            <vs-td :data="tr.item_code">
              {{ tr.item_code }}
            </vs-td>
            <vs-td :data="tr.quantity">
              {{ tr.quantity }}-{{ tr.item_unit }}
            </vs-td>
            <vs-td>
              <div class="vx-row">
                <vs-input
                  class="w-2/3"
                  v-model="tr.adjusted_quantity"
                  disabled
                  type="number"
                />
                <vs-input
                  class="w-1/3"
                  v-model="tr.accepted_item.unit_name"
                  disabled
                />
              </div>
            </vs-td>
            <vs-td>
              <div
                class="w-full flex justify-end gap-2"
                v-for="(tk, index) in itemLine[indextr].rejected_reason"
                :key="'po' + index"
                :data="tk"
              >
                <div class="w-3/4 flex flex-col justify-end">
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span>HU:</span>
                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index]"
                      :options="tr.item_units"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="unit_name"
                      :disabled="
                        disableRejectAndAccept ||
                        !itemLine[indextr].is_allow_partial_item
                      "
                      @select="rejectedCheck(indextr, index)"
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-4">
                    <span>Batch:</span>
                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index].batches"
                      :options="tr.batches"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="delivery_order_line_id"
                      :custom-label="customLabelBatch"
                      :disabled="
                        disableRejectAndAccept ||
                        !itemLine[indextr].is_allow_partial_item
                      "
                      @select="
                        selectBatch(
                          indextr,
                          index,
                          tr.rejected_reason[index].batches
                        )
                      "
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span class="w-1/8">Qty:</span>
                    <vs-input
                      class="w-full"
                      v-model="tr.rejected_reason[index].quantity"
                      :disabled="
                        disableRejectAndAccept ||
                        !itemLine[indextr].is_allow_partial_item
                      "
                      type="number"
                      :min="0"
                      :max="tr.rejected_reason[index].quantity_max"
                      @input="rejectedCheck(indextr, index)"
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-2">
                    <span>Reason:</span>

                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index].reason"
                      :options="optionReason"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="reason"
                      :disabled="
                        disableRejectAndAccept ||
                        !itemLine[indextr].is_allow_partial_item
                      "
                    />
                  </div>
                  <br v-if="itemLine[indextr].rejected_reason.length > 1" />
                </div>
                <div class="vx-col w-1/4 flex flex-col justify-center">
                  <div class="vx-input-group flex">
                    <vs-button
                      v-if="
                        itemLine[indextr].rejected_reason.length > 1 &&
                        !disableRejectAndAccept &&
                        itemLine[indextr].is_allow_partial_item
                      "
                      @click.stop="removeRowReason(indextr, index)"
                      size="small"
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      style="margin-right: 5px"
                      title="Remove Row"
                    />

                    <vs-button
                      v-if="
                        !disableRejectAndAccept &&
                        itemLine[indextr].is_allow_partial_item
                      "
                      @click.stop="addRowReason(indextr)"
                      size="small"
                      color="success"
                      icon-pack="feather"
                      icon="icon-plus"
                      title="Add Row"
                    />
                  </div>
                </div>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <vs-input
                  class="w-full"
                  v-model="tr.notes"
                  :disabled="disabledReason"
                  type="text"
                />
              </div>
            </vs-td>
          </vs-tr>
          <tr
            v-for="index in addTr"
            :key="'kl' + index"
            style="background: transparent"
          >
            <td></td>
          </tr>
        </template>
      </vs-table>

      <div v-if="itemCombo.length > 0" class="separator">
        <span>COMBO</span>
      </div>
      <div v-if="ComboMaster.length > 0" class="w-full">
        <vs-table
          stripe
          border
          description
          :sst="true"
          :data="ComboMaster"
          :max-items="ComboMaster.length"
        >
          <template slot="thead">
            <vs-th style="width: 10%">Combo Code</vs-th>
            <vs-th style="width: 5%">Quantity Total</vs-th>
            <vs-th style="width: 5%">Accepted Quantity</vs-th>
            <vs-th style="width: 5%">Rejected Quantity</vs-th>
            <vs-th style="width: 15%">Reason </vs-th>
            <vs-th style="width: 10%">Details</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="combo_id" v-for="(tr, combo_id) in data">
              <vs-td :data="tr.combo_code">
                {{ tr.combo_code }}
              </vs-td>
              <vs-td :data="tr.combo_quantity_real">
                <vs-input
                  class="w-2/3"
                  v-model="tr.combo_quantity_real"
                  disabled
                  type="number"
                />
              </vs-td>
              <vs-td :data="tr.combo_quantity_accepted">
                <vs-input
                  class="w-2/3"
                  v-model="tr.combo_quantity_accepted"
                  disabled
                  type="number"
                />
              </vs-td>
              <vs-td>
                <div class="vx-row">
                  <vs-input
                    class="w-2/3"
                    v-model="tr.combo_quantity_rejected"
                    type="number"
                    @input="rejectedComboCheck(combo_id, tr.combo_id)"
                    :disabled="
                      disableRejectAndAccept && tr.is_allow_partial_item
                    "
                  />
                </div>
              </vs-td>
              <vs-td>
                <multiselect
                  class="selectExample"
                  v-model="tr.reason"
                  :options="optionReason"
                  :multiple="false"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="100"
                  :limit="3"
                  placeholder="Select one"
                  :searchable="true"
                  track-by="id"
                  label="reason"
                  @select="rejectedComboCheckReason(combo_id, tr.combo_id)"
                  :disabled="disableRejectAndAccept && tr.is_allow_partial_item"
                />
              </vs-td>
              <vs-td>
                <vs-button
                  v-if="!isDetailCombo"
                  @click.stop="handleDetailsCombo(tr.combo_id)"
                  size="small"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-eye"
                  title="Details"
                />
                <vs-button
                  v-if="isDetailCombo"
                  @click.stop="handleDetailsCombo(tr.combo_id)"
                  size="small"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-eye-off"
                  title="Details"
                />
              </vs-td>
            </vs-tr>
            <br />
            <br />
            <br />
            <br />
            <tr
              v-for="index in addTr"
              :key="index"
              style="background: transparent"
            >
              <td></td>
            </tr>
          </template>
        </vs-table>
      </div>
      <vs-table
        v-if="detailCombo.length > 0 && isDetailCombo"
        stripe
        border
        description
        :sst="true"
        :data="detailCombo"
        :max-items="detailCombo.length"
      >
        <template slot="thead">
          <vs-th style="width: 28%">SKU Name</vs-th>
          <vs-th style="width: 12%">SKU Code</vs-th>
          <vs-th style="width: 5%">Quantity</vs-th>
          <vs-th style="width: 10%">Accepted Quantity</vs-th>
          <vs-th style="width: 30%">Rejected Quantity</vs-th>
          <vs-th style="width: 15%">Note</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="'cf' + indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.item_name">
              {{ tr.item_name }}
            </vs-td>
            <vs-td :data="tr.item_code">
              {{ tr.item_code }}
            </vs-td>
            <vs-td :data="tr.quantity">
              {{ tr.quantity }}-{{ tr.item_unit }}
            </vs-td>
            <vs-td>
              <div class="vx-row">
                <vs-input
                  class="w-2/3"
                  v-model="tr.adjusted_quantity"
                  disabled
                  type="number"
                />
                <vs-input
                  class="w-1/3"
                  v-model="tr.accepted_item.unit_name"
                  disabled
                />
              </div>
            </vs-td>
            <vs-td>
              <div
                class="w-full flex justify-start gap-2"
                v-for="(tk, index) in itemCombo[indextr].rejected_reason"
                :key="'tu' + index"
                :data="tk"
              >
                <div class="w-3/4 flex flex-col justify-end">
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span>HU:</span>
                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index]"
                      :options="tr.item_units"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="unit_name"
                      disabled
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span class="w-1/8">Qty:</span>
                    <vs-input
                      class="w-full"
                      v-model="tr.rejected_reason[index].quantity"
                      disabled
                      type="number"
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-2">
                    <span>Reason:</span>

                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index].reason"
                      :options="optionReason"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="reason"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <vs-input
                  class="w-full"
                  v-model="tr.notes"
                  disabled
                  type="text"
                />
              </div>
            </vs-td>
          </vs-tr>
          <tr
            v-for="index in addTr"
            :key="index"
            style="background: transparent"
          >
            <td></td>
          </tr>
        </template>
      </vs-table>

      <div v-if="itemFree.length > 0" class="separator">
        <span>FREE</span>
      </div>
      <vs-table
        v-if="itemFree.length > 0"
        stripe
        border
        description
        :sst="true"
        :data="itemFree"
        :max-items="itemFree.length"
      >
        <template slot="thead">
          <vs-th style="width: 28%">SKU Name</vs-th>
          <vs-th style="width: 12%">SKU Code</vs-th>
          <vs-th style="width: 5%">Quantity</vs-th>
          <vs-th style="width: 10%">Accepted Quantity</vs-th>
          <vs-th style="width: 30%">Rejected Quantity</vs-th>
          <vs-th style="width: 15%">Note</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="'bm' + indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.item_name">
              {{ tr.item_name }}
            </vs-td>
            <vs-td :data="tr.item_code">
              {{ tr.item_code }}
            </vs-td>

            <vs-td :data="tr.quantity">
              {{ tr.quantity }}-{{ tr.item_unit }}
            </vs-td>
            <vs-td>
              <div class="vx-row">
                <vs-input
                  class="w-2/3"
                  v-model="tr.adjusted_quantity"
                  disabled
                  type="number"
                />
                <vs-input
                  class="w-1/3"
                  v-model="tr.accepted_item.unit_name"
                  disabled
                />
              </div>
            </vs-td>
            <vs-td>
              <div
                class="w-full flex justify-end gap-2"
                v-for="(tk, index) in itemFree[indextr].rejected_reason"
                :key="'qe' + index"
                :data="tk"
              >
                <div class="w-3/4 flex flex-col justify-end">
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span>HU:</span>
                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index]"
                      :options="tr.item_units"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="unit_name"
                      disabled
                      @select="rejectedFreeCheck(indextr, index)"
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-4">
                    <span>Batch:</span>
                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index].batch_rejected"
                      :options="tr.batches"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="batch"
                      disabled
                    />
                  </div>
                  <div class="w-full flex flex-row justify-end gap-10">
                    <span class="w-1/8">Qty:</span>
                    <vs-input
                      class="w-full"
                      v-model="tr.rejected_reason[index].quantity"
                      disabled
                      type="number"
                      @input="rejectedFreeCheck(indextr, index)"
                    />
                  </div>

                  <div class="w-full flex flex-row justify-end gap-2">
                    <span>Reason:</span>

                    <multiselect
                      class="selectExample"
                      v-model="tr.rejected_reason[index].reason"
                      :options="optionReason"
                      :multiple="false"
                      :allow-empty="true"
                      :group-select="false"
                      :max-height="100"
                      :limit="3"
                      placeholder="Select one"
                      :searchable="true"
                      track-by="id"
                      label="reason"
                      disabled
                    />
                  </div>
                  <br v-if="itemFree[indextr].rejected_reason.length > 1" />
                </div>
                <div class="vx-col w-1/4 flex flex-col justify-center">
                  <div class="vx-input-group flex">
                    <!-- <vs-button
                      v-if="
                        itemFree[indextr].rejected_reason.length > 1 &&
                        !disableRejectAndAccept
                      "
                      @click.stop="removeRowFreeReason(indextr, index)"
                      size="small"
                      color="danger"
                      icon-pack="feather"
                      icon="icon-trash"
                      style="margin-right: 5px"
                      title="Remove Row"
                    />

                    <vs-button
                      v-if="!disableRejectAndAccept"
                      @click.stop="addRowFreeReason(indextr)"
                      size="small"
                      color="success"
                      icon-pack="feather"
                      icon="icon-plus"
                      title="Add Row"
                    /> -->
                  </div>
                </div>
              </div>
            </vs-td>
            <vs-td>
              <div>
                <vs-input
                  class="w-full"
                  v-model="tr.notes"
                  disabled
                  type="text"
                />
              </div>
            </vs-td>
          </vs-tr>
          <tr
            v-for="index in addTr"
            :key="index"
            style="background: transparent"
          >
            <td></td>
          </tr>
        </template>
      </vs-table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <!-- <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        /> -->
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},

  async mounted() {
    this.handleCheckEditOrShow();
    let params = {
      length: 999,
      order: "id",
      sort: "asc",
      reason_groups_name: ["POD Failed", "POD Partial"],
    };

    //check if selectedStatus not selected then set disableRejectAndAccept and disabledReason to true

    await this.getData();
    await this.getReasons(params);
  },

  data() {
    return {
      isAllowPartial: true,
      editOrShow: false,
      table: this.tableDefaultState(),
      warehouseID: null,

      dateNow: moment().format("YYYY-MM-DD"),
      selectedStatus: { name: "", ID: 0 },
      optionalStatus: [
        { name: "Complete", ID: 1 },
        { name: "Partial Complete ", ID: 2 },
        // { name: "Partial Redeliver ", ID: 3 },
        { name: "Failed Cancel", ID: 4 },
        { name: "Failed Redeliver", ID: 5 },
        { name: "Pending", ID: 6 },
      ],
      disableRejectAndAccept: true,
      disabledReason: true,
      addTr: 3,
      optionReason: [],
      optionReasonMain: [],
      selectedReason: null,
      isDelivered: false,
      itemLine: [],
      itemCombo: [],
      itemFree: [],
      ComboMaster: [],
      isDetailCombo: false,
      detailCombo: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },

    async getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/proof-of-delivery/edit-podV2", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((resp) => {
          // console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data;
            this.isAllowPartial = this.table.data.is_allow_partial;
            if (this.isAllowPartial == false) {
              //set optionalStatus disable Partial Complete
              this.optionalStatus = this.optionalStatus.filter(
                (status) => status.ID != 2
              );
            }

            this.table.data.proof_of_delivery_date =
              this.table.data.proof_of_delivery_date != ""
                ? moment(this.table.data.proof_of_delivery_date).format(
                    "YYYY-MM-DD"
                  )
                : moment().format("YYYY-MM-DD");
            this.isDelivered = resp.data.is_delivered;

            this.optionalStatus.forEach((element) => {
              if (element.ID == this.table.data.status_delivery) {
                this.selectedStatus = element;
              }
            });
            //set is_document default value true
            if (this.table.data.is_document) {
              this.table.data.is_document = resp.data.is_document;
            } else {
              this.table.data.is_document = true;
            }

            for (let index = 0; index < resp.data.items_pod.length; index++) {
              // console.log("JJJJJJ", this.table.data.items_pod[index]);

              let dataAccept = null;
              if (this.table.data.status_delivery == 2) {
                dataAccept = this.table.data.items_pod[index].item_units.filter(
                  (unit) => unit.level == 1
                )[0];

                //change quantity to table.data.items_pod[index].adjusted_quantity
                dataAccept.quantity =
                  this.table.data.items_pod[index].adjusted_quantity;
              } else {
                dataAccept = this.table.data.items_pod[index].item_units.filter(
                  (unit) =>
                    unit.id == this.table.data.items_pod[index].item_unit_id
                )[0];
              }

              var itemUnitIDUOM = 0;
              if (this.selectedStatus.ID == 2) {
                this.table.data.items_pod[index].item_units.forEach(
                  (element) => {
                    if (element.level == 1) {
                      itemUnitIDUOM = element.id;
                    }
                  }
                );
              }

              let responseRejectedReason = [];
              let isRejected = false;

              if (this.table.data.items_pod[index].rejected_reason != null) {
                isRejected = true;
                responseRejectedReason =
                  this.table.data.items_pod[index].rejected_reason;

                // //append item_unit_id to rejected_reason
                // responseRejectedReason.forEach((element) => {
                //   element.id = itemUnitIDUOM;
                // });
                this.table.data.items_pod[index].data_dol.forEach((element) => {
                  responseRejectedReason.forEach((element2) => {
                    if (
                      element.delivery_order_line_id ==
                      element2.delivery_order_line_id
                    ) {
                      element2.batches = element;
                      element2.batch_rejected = element.batch;
                      element2.quantity_max = element.amount_uom_total;
                    }
                  });
                });
              } else {
                if (
                  this.table.data.status_delivery == 4 ||
                  this.table.data.status_delivery == 5 ||
                  this.table.data.status_delivery == 6
                ) {
                  responseRejectedReason = [
                    {
                      idxRs: 1,
                      amount_uom: 0,
                      level: 0,
                      unit_name: "",
                      unit_id: 0,
                      id: itemUnitIDUOM,
                      quantity: this.table.data.items_pod[index].qty_after_conv,
                      is_new: false,
                      reason: this.selectedReason,
                      batch_rejected: null,
                    },
                  ];
                } else {
                  responseRejectedReason = [
                    {
                      amount_uom: 0,
                      level: 0,
                      unit_name: "",
                      unit_id: 0,
                      id: itemUnitIDUOM,
                      quantity: null,
                      is_new: false,
                      reason: null,
                      batch_rejected: null,
                    },
                  ];
                }
              }
              //append data line to itemLine

              //check if data is_reguler then append to itemLine if not then append to itemCombo or itemFree
              if (resp.data.items_pod[index].is_reguler) {
                var batchExist = [];
                //check if delivery_order_line_id exist in responseRejectedReason then append delivery_order_line_id to batchExist
                responseRejectedReason.forEach((element) => {
                  batchExist.push(element.delivery_order_line_id);
                });
                this.itemLine.push({
                  id: resp.data.items_pod[index].id,
                  delivery_order_line_id:
                    resp.data.items_pod[index].delivery_order_line_id,
                  item_code: resp.data.items_pod[index].item_code,
                  item_name: resp.data.items_pod[index].item_name,
                  item_unit: resp.data.items_pod[index].item_unit,
                  quantity: resp.data.items_pod[index].quantity,
                  amount_uom: resp.data.items_pod[index].amount_uom,
                  reason_id: resp.data.items_pod[index].reason_id,
                  notes: resp.data.items_pod[index].notes,
                  batches: resp.data.items_pod[index].data_dol,
                  is_combo: resp.data.items_pod[index].is_combo,
                  is_reguler: resp.data.items_pod[index].is_reguler,
                  is_free: resp.data.items_pod[index].is_free,
                  combo_id: resp.data.items_pod[index].combo_id,
                  combo_code: resp.data.items_pod[index].combo_code,
                  combo_quantity: resp.data.items_pod[index].combo_quantity,
                  combo_quantity_general:
                    resp.data.items_pod[index].combo_quantity_general,
                  item_units: resp.data.items_pod[index].item_units,
                  qty_after_conv:
                    resp.data.items_pod[index].quantity *
                    resp.data.items_pod[index].amount_uom,
                  //if adjust qty is null then set to item after conv
                  adjusted_quantity: resp.data.items_pod[index]
                    .adjusted_quantity
                    ? resp.data.items_pod[index].adjusted_quantity
                    : 0,

                  accepted_item: dataAccept,

                  return_quantity: resp.data.items_pod[index].return_quantity
                    ? resp.data.items_pod[index].return_quantity
                    : resp.data.items_pod[index].quantity *
                      resp.data.items_pod[index].amount_uom,
                  rejected_reason: responseRejectedReason,
                  is_allow_partial_item:
                    resp.data.items_pod[index].is_allow_partial_item,
                  is_rejected: isRejected,
                });
              } else if (resp.data.items_pod[index].is_combo) {
                this.itemCombo.push({
                  id: resp.data.items_pod[index].id,
                  delivery_order_line_id:
                    resp.data.items_pod[index].delivery_order_line_id,
                  item_code: resp.data.items_pod[index].item_code,
                  item_name: resp.data.items_pod[index].item_name,
                  item_unit: resp.data.items_pod[index].item_unit,
                  quantity: resp.data.items_pod[index].quantity,
                  reason_id: resp.data.items_pod[index].reason_id,
                  notes: resp.data.items_pod[index].notes,
                  batches: resp.data.items_pod[index].data_dol,
                  is_combo: resp.data.items_pod[index].is_combo,
                  is_reguler: resp.data.items_pod[index].is_reguler,
                  is_free: resp.data.items_pod[index].is_free,
                  combo_id: resp.data.items_pod[index].combo_id,
                  combo_code: resp.data.items_pod[index].combo_code,
                  combo_quantity: resp.data.items_pod[index].combo_quantity,
                  combo_quantity_general:
                    resp.data.items_pod[index].combo_quantity_general,
                  item_units: resp.data.items_pod[index].item_units,
                  qty_after_conv:
                    resp.data.items_pod[index].quantity *
                    resp.data.items_pod[index].amount_uom,
                  //if adjust qty is null then set to item after conv
                  adjusted_quantity: resp.data.items_pod[index]
                    .adjusted_quantity
                    ? resp.data.items_pod[index].adjusted_quantity
                    : 0,

                  accepted_item: dataAccept,

                  return_quantity: resp.data.items_pod[index].adjusted_quantity
                    ? resp.data.items_pod[index].return_quantity
                    : resp.data.items_pod[index].quantity *
                      resp.data.items_pod[index].amount_uom,
                  rejected_reason: responseRejectedReason,
                  is_allow_partial_item:
                    resp.data.items_pod[index].is_allow_partial_item,
                  is_rejected: isRejected,
                });
              } else {
                this.itemFree.push({
                  id: resp.data.items_pod[index].id,
                  delivery_order_line_id:
                    resp.data.items_pod[index].delivery_order_line_id,
                  item_code: resp.data.items_pod[index].item_code,
                  item_name: resp.data.items_pod[index].item_name,
                  item_unit: resp.data.items_pod[index].item_unit,
                  quantity: resp.data.items_pod[index].quantity,
                  reason_id: resp.data.items_pod[index].reason_id,
                  notes: resp.data.items_pod[index].notes,
                  batches: resp.data.items_pod[index].data_dol,
                  is_combo: resp.data.items_pod[index].is_combo,
                  is_reguler: resp.data.items_pod[index].is_reguler,
                  is_free: resp.data.items_pod[index].is_free,
                  combo_id: resp.data.items_pod[index].combo_id,
                  combo_code: resp.data.items_pod[index].combo_code,
                  combo_quantity: resp.data.items_pod[index].combo_quantity,
                  combo_quantity_general:
                    resp.data.items_pod[index].combo_quantity_general,
                  item_units: resp.data.items_pod[index].item_units,
                  qty_after_conv:
                    resp.data.items_pod[index].quantity *
                    resp.data.items_pod[index].amount_uom,
                  //if adjust qty is null then set to item after conv
                  adjusted_quantity: resp.data.items_pod[index]
                    .adjusted_quantity
                    ? resp.data.items_pod[index].adjusted_quantity
                    : 0,

                  accepted_item: dataAccept,

                  return_quantity: resp.data.items_pod[index].adjusted_quantity
                    ? resp.data.items_pod[index].return_quantity
                    : resp.data.items_pod[index].quantity *
                      resp.data.items_pod[index].amount_uom,
                  rejected_reason: responseRejectedReason,
                  is_allow_partial_item:
                    resp.data.items_pod[index].is_allow_partial_item,
                  is_rejected: isRejected,
                });
              }

              //sorted itemLine by sku_name then sku_code
              this.itemLine.sort((a, b) => {
                if (a.item_name < b.item_name) {
                  return -1;
                }
                if (a.item_name > b.item_name) {
                  return 1;
                }
                return 0;
              });

              //check if status is failed_cancel then set rejected_reason quantity = quantity * amount_uom
              if (
                this.selectedStatus.ID == 4 ||
                this.selectedStatus.ID == 5 ||
                this.selectedStatus.ID == 6
              ) {
                // this.itemLine[index].adjusted_quantity = 0;
                // let itemUnitLevel1 = this.itemLine[index].item_units.filter(
                //   (unit) => unit.level == 1
                // )[0];
                // this.itemLine[index].rejected_reason[0].unit_name =
                //   itemUnitLevel1.unit_name;

                // this.itemLine[index].rejected_reason[0].unit_id =
                //   itemUnitLevel1.unit_id;
                // this.itemLine[index].rejected_reason[0].id = itemUnitLevel1.id;
                // this.itemLine[index].rejected_reason[0].amount_uom =
                //   itemUnitLevel1.amount_uom;
                // this.itemLine[index].rejected_reason[0].level =
                //   itemUnitLevel1.level;
                // this.itemLine[index].rejected_reason[0].quantity =
                //   this.itemLine[index].qty_after_conv;

                this.optionReason = this.optionReasonMain.filter(
                  (reason) => reason.reason_group == "POD Failed"
                );
                // console.log(">>>>>Reason", this.optionReason);

                //get data selectedReason from itemLine rejected_reason index 0
                this.selectedReason = this.optionReasonMain.filter(
                  (reason) => reason.id == resp.data.reason_id
                )[0];
              } else {
                this.optionReason = this.optionReasonMain.filter(
                  (reason) => reason.reason_group == "POD Partial"
                );
              }
            }

            // console.log("ini itemLine", this.itemLine);

            if (this.itemCombo.length > 0) {
              //append data to ComboMaster then remove duplicate
              this.itemCombo.forEach((element) => {
                var itemRejected = 0;
                var itemAccepeted = 0;
                if (
                  element.return_quantity != 0 &&
                  this.selectedStatus.ID != 0
                ) {
                  itemRejected =
                    element.adjusted_quantity / element.combo_quantity_general;
                  itemRejected = element.combo_quantity - itemRejected;
                  itemAccepeted = element.combo_quantity - itemRejected;
                }
                this.ComboMaster.push({
                  combo_id: element.combo_id,
                  combo_code: element.combo_code,
                  combo_quantity: element.combo_quantity,
                  combo_quantity_general: element.combo_quantity_general,
                  combo_quantity_accepted: itemAccepeted,
                  combo_quantity_rejected: itemRejected,
                  combo_quantity_real: element.combo_quantity,
                  reason:
                    element.rejected_reason[0].reason != null
                      ? element.rejected_reason[0].reason
                      : null,
                  is_allow_partial_item: element.is_allow_partial_item,
                });
              });

              this.ComboMaster = this.ComboMaster.filter(
                (v, i, a) => a.findIndex((t) => t.combo_id === v.combo_id) === i
              );
            }

            // console.log(">>>>>ComboMaster", this.ComboMaster);
            // console.log(">>>>>itemCombo", this.itemCombo);
            // console.log(">>>>>itemLine", this.itemLine);
            if (this.table.data.delivery_date > this.dateNow) {
              this.handleCheckIfDelivered();
              this.$vs.notify({
                title: "Error",
                text:
                  "You can edit this POD on the delivery date " +
                  this.table.data.delivery_date,
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
            }

            // console.log("weee", this.table.data);
            if (!this.isDelivered && this.table.data.notes == "ePOD") {
              this.handleCheckIfDelivered();
              this.$vs.notify({
                title: "Error",
                text: "This POD is not delivered yet",
                color: "warning",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-alert-circle",
              });
            }

            if (this.selectedStatus.ID != 2) {
              this.handleSelectStatus();
            }

            // if (this.selectedReason != null && this.selectedStatus.ID != 2) {
            //   this.handleSelectReason();
            // }
            if (this.selectedStatus.ID == 0) {
              this.disableRejectAndAccept = true;
              this.disabledReason = true;
            }
            for (let index = 0; index < this.itemLine.length; index++) {
              if (this.itemLine[index].is_rejected) {
                let data = this.itemLine[index];
                let totalQty = 0;
                data.rejected_reason.forEach((element) => {
                  //convert to level 1
                  let qty = element.quantity;
                  if (element.level != 1) {
                    qty = element.quantity * element.amount_uom;
                  }
                  totalQty += Number(qty);
                });
                data.adjusted_quantity =
                  data.qty_after_conv - totalQty < 0
                    ? data.quantity * data.amount_uom
                    : data.qty_after_conv - totalQty;

                data.adj_qty_old = data.adjusted_quantity;
              }
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    SubmitForm(value) {
      console.log(">>>>>ini value", value);

      var isProcess = value;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Are you sure to Save this data?",
            accept: () => {
              this.submit(isProcess);
            },
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Please complete the form",
            color: "warning",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      });
    },

    submit(value) {
      console.log(">>>>>ini value", value);
      let itemData = [];
      //append itemLine, itemCombo, itemFree to itemData
      itemData = itemData.concat(this.itemLine, this.itemCombo, this.itemFree);
      if (!this.selectedStatus.ID) {
        this.$vs.notify({
          title: "Error",
          text: "Please select status",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return;
      }

      if ([2, 4, 5, 6].includes(this.selectedStatus.ID)) {
        let isReasonSelected = itemData.some((item) =>
          item.rejected_reason.some((reason) => {
            if (reason.reason) {
              item.reason_id = reason.reason.id;
              return true;
            }
            return false;
          })
        );

        if (this.selectedStatus.ID === 2) {
          const allAdjustedQtyZero = itemData.every(
            (item) => item.adjusted_quantity === 0
          );

          // Convert rejected_reason quantities to numbers and set item_unit_id
          itemData.forEach((item) =>
            item.rejected_reason.forEach((reason) => {
              reason.quantity = Number(reason.quantity);
              reason.item_unit_id = reason.id;
            })
          );

          if (allAdjustedQtyZero) {
            this.$vs.notify({
              title: "Error",
              text: "This POD should be set status to Failed Cancel",
              color: "warning",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            return;
          }

          const allAdjustedQtyEqual = itemData.every(
            (item) => item.adjusted_quantity === item.qty_after_conv
          );

          if (allAdjustedQtyEqual) {
            this.$vs.notify({
              title: "Error",
              text: "This POD should be set status to Complete",
              color: "warning",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
            return;
          }
        }

        if (!isReasonSelected) {
          this.$vs.notify({
            title: "Error",
            text: "Please select reason",
            color: "warning",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }
      }

      // Prepare data for submission
      const submitItem = {
        id: this.table.data.id,
        delivery_order_id: this.table.data.delivery_order_id,
        delivery_order_code: this.table.data.delivery_order_code,
        transport_plan_id: this.table.data.transport_plan_id,
        transport_plan_code: this.table.data.shipment_number,
        proof_of_delivery_date: this.table.data.proof_of_delivery_date,
        sales_order_code: this.table.data.sales_order_code,
        status_delivery: this.selectedStatus.ID,
        is_document: this.table.data.is_document,
        reason_id: this.selectedReason ? this.selectedReason.id : 0,
        items_pod: itemData,
      };

      // Send data to the server
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/proof-of-delivery/save-V2", {
          proof_of_delivery: submitItem,
          is_proccessed: value,
          e_pod: "ePOD",
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code === 200) {
            this.$vs.notify({
              title: "Success",
              text: "Proof of Delivery has been saved",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        });
    },
    async getReasons(params) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/reason", {
          params: params,
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionReasonMain = resp.data.records;
              if (this.optionReasonMain.length > 0) {
              } else {
                this.optionReasonMain = [];
                this.selectedReason = { reason: "" };
              }
            } else {
              this.optionReasonMain = [];
              this.selectedReason = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },

    customLabelReason({ reason }) {
      return `${reason} `;
    },
    customLabelStatus({ name }) {
      return `${name} `;
    },
    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "proof-of-delivery" });
      this.$vs.loading.close();
    },
    handleSelectStatus() {
      if (this.selectedStatus.ID == 5 || this.selectedStatus.ID == 6) {
        this.disableRejectAndAccept = true;
        this.itemLine.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.item_unit == item.item_units[index].unit_name) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;
          item.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: item.return_quantity,
              is_new: false,
              reason: this.selectedReason,
            },
          ];

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.quantity;
          item.return_quantity = item.quantity;
        });
        this.itemCombo.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.item_unit == item.item_units[index].unit_name) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;
          item.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: item.return_quantity,
              is_new: false,
              reason: this.selectedReason,
            },
          ];

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.quantity;
          item.return_quantity = item.quantity;
        });
        this.itemFree.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.item_unit == item.item_units[index].unit_name) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;
          item.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: item.return_quantity,
              is_new: false,
              reason: this.selectedReason,
            },
          ];

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.quantity;
          item.return_quantity = item.quantity;
        });
        this.optionReason = this.optionReasonMain.filter(
          (reason) => reason.reason_group == "POD Failed"
        );
        this.ComboMaster.forEach((tr) => {
          tr.reason = this.selectedReason;
          tr.combo_quantity_rejected = tr.combo_quantity_real;
          tr.combo_quantity_accepted = 0;
        });
      } else if (this.selectedStatus.ID == 1) {
        this.selectedReason = 0;
        this.itemLine.forEach((tr) => {
          // tr.adjusted_quantity = tr.quantity;
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => b.level - a.level);

          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.qty_after_conv % tr.item_units[index].amount_uom == 0) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity =
                tr.qty_after_conv / tr.item_units[index].amount_uom;
              tr.return_quantity = 0;
              break;
            }
          }

          //for rejected reason set to 0
          tr.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: 0,
              is_new: false,
              reason: null,
            },
          ];
        });
        this.itemCombo.forEach((tr) => {
          // tr.adjusted_quantity = tr.quantity;
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => b.level - a.level);

          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.qty_after_conv % tr.item_units[index].amount_uom == 0) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity =
                tr.qty_after_conv / tr.item_units[index].amount_uom;
              tr.return_quantity = 0;
              break;
            }
          }

          //for rejected reason set to 0
          tr.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: 0,
              is_new: false,
              reason: null,
            },
          ];
        });
        this.itemFree.forEach((tr) => {
          // tr.adjusted_quantity = tr.quantity;
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => b.level - a.level);

          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.qty_after_conv % tr.item_units[index].amount_uom == 0) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity =
                tr.qty_after_conv / tr.item_units[index].amount_uom;
              tr.return_quantity = 0;
              break;
            }
          }

          //for rejected reason set to 0
          tr.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: 0,
              is_new: false,
              reason: null,
            },
          ];
        });
        this.ComboMaster.forEach((tr) => {
          tr.reason = null;
          tr.combo_quantity_rejected = 0;
          tr.combo_quantity_accepted = tr.combo_quantity_real;
        });
      } else if (this.selectedStatus.ID == 2) {
        this.itemLine.forEach((tr) => {
          this.disabledReason = false;
          this.disableRejectAndAccept = false;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => a.level - b.level);
          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.item_units[index].level == 1) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity = tr.qty_after_conv;
              tr.rejected_reason = [
                {
                  amount_uom: tr.item_units[index].amount_uom,
                  level: tr.item_units[index].level,
                  unit_name: tr.item_units[index].unit_name,
                  unit_id: tr.item_units[index].unit_id,
                  id: tr.item_units[index].id,
                  quantity: null,
                  is_new: false,
                  reason: null,
                },
              ];
              tr.return_quantity = 0;
              break;
            }
          }
        });
        this.itemCombo.forEach((tr) => {
          this.disabledReason = false;
          this.disableRejectAndAccept = false;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => a.level - b.level);
          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.item_units[index].level == 1) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity = tr.qty_after_conv;
              tr.rejected_reason = [
                {
                  amount_uom: tr.item_units[index].amount_uom,
                  level: tr.item_units[index].level,
                  unit_name: tr.item_units[index].unit_name,
                  unit_id: tr.item_units[index].unit_id,
                  id: tr.item_units[index].id,
                  quantity: null,
                  is_new: false,
                  reason: null,
                },
              ];
              tr.return_quantity = 0;
              break;
            }
          }
        });
        this.itemFree.forEach((tr) => {
          this.disabledReason = false;
          this.disableRejectAndAccept = false;
          tr.reason_id = null;
          tr.notes = null;

          tr.item_units.sort((a, b) => a.level - b.level);
          for (let index = 0; index < tr.item_units.length; index++) {
            if (tr.item_units[index].level == 1) {
              tr.accepted_item = tr.item_units[index];
              tr.adjusted_quantity = tr.qty_after_conv;
              tr.rejected_reason = [
                {
                  amount_uom: tr.item_units[index].amount_uom,
                  level: tr.item_units[index].level,
                  unit_name: tr.item_units[index].unit_name,
                  unit_id: tr.item_units[index].unit_id,
                  id: tr.item_units[index].id,
                  quantity: null,
                  is_new: false,
                  reason: null,
                },
              ];
              tr.return_quantity = 0;
              break;
            }
          }
        });

        this.optionReason = this.optionReasonMain.filter(
          (reason) => reason.reason_group == "POD Partial"
        );
        this.ComboMaster.forEach((tr) => {
          tr.reason = null;
          tr.combo_quantity_rejected = 0;
          tr.combo_quantity_accepted = tr.combo_quantity_real;
        });
      } else if (this.selectedStatus.ID == 4) {
        this.disableRejectAndAccept = true;
        this.itemLine.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.item_unit == item.item_units[index].unit_name) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;
          item.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: item.return_quantity,
              is_new: false,
              reason: this.selectedReason,
            },
          ];

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.quantity;
          item.return_quantity = item.quantity;
        });
        this.itemCombo.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.item_unit == item.item_units[index].unit_name) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;
          item.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: item.return_quantity,
              is_new: false,
              reason: this.selectedReason,
            },
          ];

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.return_quantity;
          item.return_quantity = item.quantity;
        });
        this.itemFree.forEach((item) => {
          item.adjusted_quantity = null;
          item.adjusted_quantity = 0;
          item.item_units.sort((a, b) => b.level - a.level);

          let itemUnitLevel = null;
          for (let index = 0; index < item.item_units.length; index++) {
            if (item.item_unit == item.item_units[index].unit_name) {
              itemUnitLevel = item.item_units[index];
              item.return_quantity =
                item.qty_after_conv / item.item_units[index].amount_uom;
              item.adjusted_quantity = 0;
              break;
            }
          }

          item.accepted_item = itemUnitLevel;
          item.rejected_reason = [
            {
              amount_uom: 0,
              level: 0,
              unit_name: "",
              unit_id: 0,
              id: 0,
              quantity: item.return_quantity,
              is_new: false,
              reason: this.selectedReason,
            },
          ];

          item.rejected_reason[0].unit_name = itemUnitLevel.unit_name;
          item.rejected_reason[0].unit_id = itemUnitLevel.unit_id;
          item.rejected_reason[0].id = itemUnitLevel.id;
          item.rejected_reason[0].amount_uom = itemUnitLevel.amount_uom;
          item.rejected_reason[0].level = itemUnitLevel.level;
          item.rejected_reason[0].quantity = item.quantity;
          item.return_quantity = item.quantity;
        });
        this.optionReason = this.optionReasonMain.filter(
          (reason) => reason.reason_group == "POD Failed"
        );
        this.ComboMaster.forEach((tr) => {
          tr.reason = this.selectedReason;
          tr.combo_quantity_rejected = tr.combo_quantity_real;
          tr.combo_quantity_accepted = 0;
        });
      } else {
        this.disabledReason = false;
        this.disableRejectAndAccept = false;
      }
    },

    rejectedCheck(indextr, index) {
      //check if unit_name was selected
      let data = this.itemLine[indextr];
      console.log(">>>>>ini data", data);

      if (data.rejected_reason[index].unit_name != "") {
        let totalQty = 0;
        let QtyCalculate = 0;
        data.rejected_reason.forEach((element) => {
          //convert to level 1
          let qty = element.quantity;
          if (element.level != 1) {
            qty = element.quantity * element.amount_uom;
          }
          QtyCalculate += Number(qty);
          console.log(
            ">>>>>QtyCalculate",
            QtyCalculate,
            qty,
            data.qty_after_conv
          );

          if (
            data.rejected_reason[index].quantity_max < qty ||
            qty < 0 ||
            data.qty_after_conv < QtyCalculate
          ) {
            this.$vs.notify({
              title: "Error",
              text: "Total quantity input can't be greater than Total Qty or less than 0",
              color: "danger",
              position: "top-right",
            });
            data.rejected_reason[index].quantity = 0;
            data.adjusted_quantity = data.qty_after_conv - QtyCalculate;
            return false;
          }
          totalQty += Number(qty);
        });

        console.log(">>>>>masuk sini", totalQty, data.qty_after_conv);

        data.adj_qty_old = data.adjusted_quantity;

        data.adjusted_quantity =
          data.qty_after_conv - totalQty < 0
            ? data.quantity * data.amount_uom
            : data.qty_after_conv - totalQty;
        data.return_quantity = totalQty;

        // console.log(
        //   ">>>>>Rejected",
        //   data.adjusted_quantity,
        //   data.qty_after_conv,
        //   adjustedQty,
        //   totalQty,
        //   allTotalQty,
        //   totalQtyOld
        // );
      }
      if (data.rejected_reason[index].batch_rejected.length > 1) {
        let batch = data.rejected_reason.filter(
          (item) =>
            item.delivery_order_line_id ==
            data.rejected_reason[index].delivery_order_line_id
        );
        if (batch.length > 1) {
          let maxTotQtyBatch = 0;
          batch.forEach((element) => {
            maxTotQtyBatch += Number(element.quantity);
            if (maxTotQtyBatch > data.rejected_reason[index].quantity_max) {
              this.$vs.notify({
                title: "Error",
                text: "Total quantity input can't be greater than Total Qty or less than 0",
                color: "danger",
                position: "top-right",
              });
              data.rejected_reason[index].quantity = 0;
            }
          });
        }
        // console.log(">>>>>Batch", batch);
      }
    },
    handleSelectReason() {
      //set all reason_id on itemLine.rejected_reason to selectedReason
      const updateRejectedReasons = (items, reason) => {
        if (items == this.ComboMaster) {
          items.forEach((item) => {
            item.reason = reason;
          });
        } else {
          items.forEach((item) => {
            item.rejected_reason.forEach((r) => {
              r.reason = reason;
            });
          });
        }
      };

      // Update rejected reasons for all item groups
      updateRejectedReasons(this.itemLine, this.selectedReason);
      updateRejectedReasons(this.itemCombo, this.selectedReason);
      updateRejectedReasons(this.itemFree, this.selectedReason);
      updateRejectedReasons(this.ComboMaster, this.selectedReason);
    },
    handleCheckEditOrShow() {
      if (this.$route.query.action == "Show") {
        this.disableRejectAndAccept = true;
        this.disabledReason = true;
        this.editOrShow = true;
      }
    },
    handleCheckIfDelivered() {
      this.disableRejectAndAccept = true;
      this.disabledReason = true;
      this.editOrShow = true;
    },
    addRowReason(indextr) {
      const item = this.itemLine[indextr];
      // Cek apakah jumlah rejected_reason melebihi jumlah batches
      if (item.rejected_reason.length >= item.batches.length) {
        this.$vs.notify({
          title: "Error",
          text: `You can only add ${item.batches.length} row(s).`,
          color: "danger",
          position: "top-right",
        });
        return true;
      }
      const unit = this.itemLine[indextr].item_units.find(
        (item) => item.level == 1
      );

      if (unit) {
        this.itemLine[indextr].rejected_reason.push({
          amount_uom: unit.amount_uom,
          level: unit.level,
          unit_name: unit.unit_name,
          unit_id: unit.unit_id,
          id: unit.id,
          quantity: null,
          is_new: true,
          reason: null,
        });
      }

      // console.log(">>>>111", this.itemLine[indextr].rejected_reason);
    },
    removeRowReason(indextr, index) {
      // Remove rejected reason at the specified index
      this.itemLine[indextr].rejected_reason.splice(index, 1);

      // Calculate total quantity after removing the row
      let totalQty = this.itemLine[indextr].rejected_reason.reduce(
        (acc, element) => {
          // Convert quantity to level 1 if necessary
          const qty =
            element.level === 1
              ? element.quantity
              : element.quantity * element.amount_uom;
          return acc + Number(qty);
        },
        0
      );

      // Update adjusted quantity
      const { qty_after_conv, adj_qty_old } = this.itemLine[indextr];
      this.itemLine[indextr].adjusted_quantity =
        qty_after_conv - totalQty < 0 ? adj_qty_old : qty_after_conv - totalQty;

      // Update return quantity
      this.itemLine[indextr].return_quantity = totalQty;
    },
    rejectedComboCheck(indextr, comboID) {
      //change value combo_quantity every change
      this.ComboMaster[indextr].combo_quantity =
        this.ComboMaster[indextr].combo_quantity_real -
        this.ComboMaster[indextr].combo_quantity_rejected;

      this.ComboMaster[indextr].combo_quantity_accepted =
        this.ComboMaster[indextr].combo_quantity_real -
        this.ComboMaster[indextr].combo_quantity_rejected;

      if (
        this.ComboMaster[indextr].combo_quantity < 0 ||
        this.ComboMaster[indextr].combo_quantity >
          this.ComboMaster[indextr].combo_quantity_real
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Total quantity input can't be greater than Total Qty or less than 0",
          color: "danger",
          position: "top-right",
        });
        this.ComboMaster[indextr].combo_quantity = 0;
        this.ComboMaster[indextr].combo_quantity_rejected =
          this.ComboMaster[indextr].combo_quantity_real;
        this.ComboMaster[indextr].combo_quantity_accepted = 0;
      }

      let combo = this.itemCombo.filter((item) => item.combo_id == comboID);
      for (let index = 0; index < combo.length; index++) {
        combo[index].adjusted_quantity =
          this.ComboMaster[indextr].combo_quantity *
          combo[index].combo_quantity_general;
        combo[index].return_quantity =
          this.ComboMaster[indextr].combo_quantity_rejected *
          combo[index].combo_quantity_general;
        combo[index].rejected_reason[0].quantity = combo[index].return_quantity;
        combo[index].rejected_reason[0].item_unit_id =
          combo[index].accepted_item.id;
        combo[index].rejected_reason[0].unit_name =
          combo[index].accepted_item.unit_name;
        combo[index].rejected_reason[0].unit_id =
          combo[index].accepted_item.unit_id;
        combo[index].rejected_reason[0].amount_uom =
          combo[index].accepted_item.amount_uom;
      }

      this.itemCombo.forEach((element) => {
        element.rejected_reason[0].batch_rejected = element.batches[0];
        element.rejected_reason[0].delivery_order_line_id =
          element.batches[0].delivery_order_line_id;
      });

      console.log(">>>>>ItemCombo", this.itemCombo);
    },
    rejectedComboCheckReason(indextr, comboID) {
      let combo = this.itemCombo.filter((item) => item.combo_id == comboID);
      for (let index = 0; index < combo.length; index++) {
        combo[index].rejected_reason[0].reason =
          this.ComboMaster[indextr].reason;
      }
    },
    rejectedFreeCheck(indextr, index) {
      // console.log(">>>>www", indextr, index);

      //check if unit_name was selected
      if (this.itemFree[indextr].rejected_reason[index].unit_name != "") {
        let totalQty = 0;
        this.itemFree[indextr].rejected_reason.forEach((element) => {
          //convert to level 1
          let qty = element.quantity;
          if (element.level != 1) {
            qty = element.quantity * element.amount_uom;
          }
          totalQty += Number(qty);
        });

        this.itemFree[indextr].adjusted_quantity =
          this.itemFree[indextr].qty_after_conv - totalQty < 0
            ? this.itemFree[indextr].adj_qty_old
            : this.itemFree[indextr].qty_after_conv - totalQty;

        //convert adjusted quantity to level 1
        let adjustedQty = this.itemFree[indextr].adjusted_quantity;
        if (this.itemFree[indextr].accepted_item.level != 1) {
          adjustedQty =
            this.itemFree[indextr].adjusted_quantity *
            this.itemFree[indextr].accepted_item.amount_uom;
        }
        let allTotalQty = totalQty + adjustedQty;
        let remaningQty = allTotalQty - adjustedQty;
        this.itemFree[indextr].return_quantity = remaningQty;

        if (
          allTotalQty > this.itemFree[indextr].qty_after_conv ||
          totalQty < 0
        ) {
          // console.log("sssss", totalQty, adjustedQty);
          this.$vs.notify({
            title: "Error",
            text: "Total quantity input can't be greater than Total Qty or less than 0",
            color: "danger",
            position: "top-right",
          });
          this.itemFree[indextr].rejected_reason[index].quantity = 0;

          return;
        } else {
          this.itemFree[indextr].adj_qty_old =
            this.itemFree[indextr].adjusted_quantity;
        }
      }
    },
    removeRowFreeReason(indextr, index) {
      // Remove rejected reason at the specified index
      this.itemFree[indextr].rejected_reason.splice(index, 1);

      // Calculate total quantity after removing the row
      let totalQty = this.itemFree[indextr].rejected_reason.reduce(
        (acc, element) => {
          // Convert quantity to level 1 if necessary
          const qty =
            element.level === 1
              ? element.quantity
              : element.quantity * element.amount_uom;
          return acc + Number(qty);
        },
        0
      );

      // Update adjusted quantity
      const { qty_after_conv, adj_qty_old } = this.itemFree[indextr];
      this.itemFree[indextr].adjusted_quantity =
        qty_after_conv - totalQty < 0 ? adj_qty_old : qty_after_conv - totalQty;

      // Update return quantity
      this.itemFree[indextr].return_quantity = totalQty;
    },
    addRowFreeReason(indextr) {
      const unit = this.itemFree[indextr].item_units.find(
        (item) => item.level == 1
      );

      if (unit) {
        this.itemFree[indextr].rejected_reason.push({
          amount_uom: unit.amount_uom,
          level: unit.level,
          unit_name: unit.unit_name,
          unit_id: unit.unit_id,
          id: unit.id,
          quantity: null,
          is_new: true,
          reason: null,
        });
      }

      // console.log(">>>>111", this.itemFree[indextr].rejected_reason);
    },
    selectBatch(indextr, index, item) {
      console.log(">>>>>item", item);
      this.itemLine[indextr].rejected_reason[index].batches = item;
      this.itemLine[indextr].rejected_reason[index].batch_rejected = item.batch;
      this.itemLine[indextr].rejected_reason[index].delivery_order_line_id =
        item.delivery_order_line_id;
      this.itemLine[indextr].rejected_reason[index].quantity_max =
        item.amount_uom_total;
    },
    customLabelBatch({ batch, batch_external }) {
      return `${batch}-(${batch_external}) `;
    },
    handleDetailsCombo(index) {
      this.isDetailCombo = !this.isDetailCombo;
      this.detailCombo = [];
      this.detailCombo = this.itemCombo.filter(
        (item) => item.combo_id == index
      );
    },
  },
  watch: {
    //check if status complete then set reason, note and accepted quantity disabled then set value accepted qty= quantity:
    "selectedStatus.ID": {
      handler(newVal) {
        if (newVal == 1) {
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
        } else if (newVal == 2) {
          this.disabledReason = false;
          this.disableRejectAndAccept = false;
        } else if (newVal == 5 || newVal == 4 || newVal == 6) {
          this.disabledReason = true;
          this.disableRejectAndAccept = true;
        } else {
          this.disabledReason = false;
          this.disableRejectAndAccept = true;
        }
      },
    },
    //check set adjusted quantity each itemLine is value
  },
  computed: {},
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator::before {
  margin-right: 10px;
}

.separator::after {
  margin-left: 10px;
}

.separator span {
  font-weight: bold;
}
</style>
